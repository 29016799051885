import { Routes } from '@angular/router';
import { PrivacyComponent } from './shared/components/privacy/privacy.component';
import { TermsComponent } from './shared/components/terms/terms.component';


export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'signin',
        loadChildren: () => import('./pages/signin/signin.module').then(m => m.SigninModule),
    },
    {
        path: 'signup',
        loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule),
    },
    {
        path: 'book-service',
        loadChildren: () => import('./pages/book-service/book-service.module').then(m => m.BookServiceModule),
    },
    {
        path: 'payment',
        loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
    },
    /* *** *** *** *** *** */
    {
        path: 'privacy-policy',
        component: PrivacyComponent,
        title: 'Laser - Privacy Policy',
    },
    {
        path: 'terms-conditions',
        component: TermsComponent,
        title: 'Laser - Terms and Conditions',
    },

    { path: '**', redirectTo: '', pathMatch: 'full' },

];
